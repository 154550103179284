import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Legend } from '@opengov/component-library/capital';
import { demoColorPalette } from '@opengov/component-library/capital/Legend/storybook-data';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const options = demoColorPalette.map(color => ({
  color: color.base,
  hoverColor: color.highlight,
  label: 'Example Legend Category'
}));
export const _frontmatter = {
  "title": "Legend",
  "componentId": "legend",
  "description": "Legends are used to display and control categories within a chart.",
  "activeTab": "components"
};
const layoutProps = {
  options,
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Documentation in progress------------------------`}</strong></p>
    <h2>{`Variants`}</h2>
    <PatternExample example={<Legend options={options} mdxType="Legend" />} title="List" mdxType="PatternExample" />
    <PatternExample example={<Legend options={options} isMultiSelectable={true} mdxType="Legend" />} title="Checklist" mdxType="PatternExample" />
    <h3>{`Usage`}</h3>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>...</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>...</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Configuration`}</h2>
    <PatternExample example={<Legend hideSort={true} options={options} mdxType="Legend" />} title="Hide Sort Menu" mdxType="PatternExample" />
    <h2>{`State`}</h2>
    <PatternExample example={<Legend disabled={true} options={options} mdxType="Legend" />} title="Disabled Sort Menu" mdxType="PatternExample" />
    <h2>{`Layout`}</h2>
    <PatternExample example={"..."} title="..." description={"Description about layout structure"} mdxType="PatternExample" />
    <h2>{`Content`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      